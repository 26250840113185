import { createContext, useEffect, useState } from "react";
import GtimeApiService from "../services/GtimeApiService";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSignOut } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const [signOut] = useSignOut(auth);

  const persistedUser = localStorage.getItem("user");
  const initialUser = persistedUser ? JSON.parse(persistedUser) : null;
  const [currentUser, setCurrentUser] = useState(initialUser);

  const [client, setClient] = useState(null);
  const [wallet, setWallet] = useState(null);

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  useEffect(() => {
    if (loading) {
      return;
    }

    const fetchClient = async () => {
      try {
        const client = await GtimeApiService.getClientByEmail(user.email);

        if (client) {
          setClient(client);

          if (client.wallets.length) {
            setWallet(client.wallets[0]);
          } else {
            const walletData = await GtimeApiService.getWalletByClientId(
              client.id
            );
            setWallet(walletData);
          }
        } else {
          throw new Error("CLIENT NO ENCONTRADO");
        }
      } catch (error) {
        console.error(error);
        setCurrentUser(null);
        localStorage.removeItem("user");
        signOut({ returnTo: window.location.origin });
      } finally {
        setIsLoadingUserData(false);
      }
    };

    if (user) {
      setCurrentUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      fetchClient();
    } else {
      setCurrentUser(null);
      localStorage.removeItem("user");
      setIsLoadingUserData(false);
    }
  }, [loading, user, signOut]);

  useEffect(() => {
    if (!wallet && !isLoadingUserData) {
      console.warn("WALLET DE CLIENT NO ENCONTRADA.");
    }
  }, [wallet, isLoadingUserData]);

  useEffect(() => {
    document.body.style.overflow =
      loading || isLoadingUserData ? "hidden" : "auto";
  }, [loading, isLoadingUserData]);

  return loading || isLoadingUserData ? (
    <div className="preloader-overlay">
      <div className="preloader-spinner"></div>
    </div>
  ) : (
    <UserContext.Provider value={{ user: currentUser, client, wallet }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
