import React, { useContext, useState } from "react";

import "./Home.scss";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ReactComponent as IconosTodos } from "../../assets/img-iconos-todos.svg";
import slide1 from "../../assets/slider/slide-1.jpg";
import slide2 from "../../assets/slider/slide-2.jpg";
import slide3 from "../../assets/slider/slide-3.jpg";
import slide4 from "../../assets/slider/slide-4.jpg";
import slide5 from "../../assets/slider/slide-5.jpg";
import slide6 from "../../assets/slider/slide-6.jpg";
import slide7 from "../../assets/slider/slide-7.jpg";
import slide8 from "../../assets/slider/slide-8.jpg";
import Slideshow from "../../components/Slideshow/Slideshow";
import { GlobalContext } from "../../contexts/GlobalContext";
import { kwhToCo2TonStr, kwhToMwhStr, mwhTokTonCO2eq } from "../../helpers/helpers";
import EnergyOrderCarousel from "../../components/EnergyOrderCarousel/EnergyOrderCarousel";

const Home = () => {
  const globalData = useContext(GlobalContext);

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
  );

  const chartData = {
    labels: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    datasets: [
      {
        fill: true,
        label: "Energía trazada",
        data: globalData["monthlyEnergy"],
        backgroundColor: "rgba(65, 206, 154, .45)",
        borderColor: "#30dc74",
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    locale: "es-CL",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const articles = [
    {
      title:
        "Aprovechando el poder de blockchain para la innovación del sector",
      subtitle: "Banco Interamericano de Desarrollo",
      image: "./placeholder/articles/article-1.png",
      content:
        "Las tecnologías digitales serán protagonistas en la descentralización de los sistemas energéticos en las décadas por venir. Esta fue una de las razones por la cual el BID y BIDLab, junto con otras organizaciones del sector, organizaron el Challenge Blockchain de Energía Sostenible. En esta edición, el foco estuvo en fomentar soluciones innovadoras que ayuden a mejorar la eficiencia, la transparencia y la sostenibilidad del sector de energía.",
      link: "https://gtime.cl/general/aprovechando-el-poder-de-blockchain-para-la-innovacion-del-sector/",
    },
    {
      title: "Proyecto piloto blockchain en subestaciones de Transelec",
      image: "./placeholder/articles/article-2.jpg",
      content:
        "Entre 2019 y 2020, se instalaron sistemas fotovoltaicos en cuatro subestaciones eléctricas de Transelec: Cerro Navia, Alto Jahuel, Itahue y Temuco. Se usaron medidores de energía certificados y tecnología blockchain para validar la generación y consumo. <br/><br/> Transelec es la principal empresa de transmisión en Chile, con más de 10.000 km de líneas y 60 subestaciones de 500kV y 220kV, de Arica y Parinacota a Los Lagos.",
      link: "https://gtime.cl/general/transelec-certifica-origen-renovable-de-energia-en-cuatro-subestaciones-con-tecnologia-blockchain/",
    },
    {
      title:
        "Cobre solar certificado con Sello Sol para la electromovilidad del futuro",
      image: "./placeholder/articles/article-3.png",
      content:
        "En 2018, Phineal y Codelco implementaron blockchain en la planta solar ''Pampa Elvira'', operada por Energía Llaima. La planta genera 50.000 MWh anuales y cubre más del 80% de la demanda térmica de la planta. Además, en la División Gabriela Mistral, Codelco cuenta con una planta termosolar desde 2013, evitando la emisión de 15.000 toneladas de CO2 al año. Ambas plantas tienen certificación Sello Sol y utilizan blockchain para transparentar el uso de energía limpia en la producción.",
      link: "https://gtime.cl/general/cobre-solar-y-electromovilidad-en-la-era-del-blockchain/",
    },
    {
      title:
        "Trazabilidad de energía solar con blockchain para el Programa Techos Solares Públicos",
      image: "./placeholder/articles/article-4.jpg",
      content:
        "Entre 2018 y 2020, se trazaron datos de producción solar en más de 130 plantas fotovoltaicas del Programa Techos Solares Públicos en diversas regiones de Chile.  La plataforma GTIME Blockchain midió y registró la producción fotovoltaica, identificando su origen limpio y almacenando los datos en el ''Warehouse'' del Banco Mundial. El proyecto demostró que blockchain es una herramienta clave para reconocer las fuentes de energía sustitutas de los combustibles fósiles, con información abierta y segura.",
      link: "https://gtime.cl/general/trazabilidad-de-la-energia-generada-por-los-techos-solares-publicos-con-tecnologia-blockchain/",
    },
    {
      title: "Original Green Roasters: Un cafetería con sabor a Sol",
      image: "./placeholder/articles/article-5.png",
      content:
        "En 2016, Sello Sol implementó su primer proyecto piloto de trazabilidad energética en la cafetería Original Green Roasters en Providencia, Santiago. El propietario, Daniel Álvarez, demostró su compromiso con la calidad al agregar valor a sus procesos mediante el uso de energía solar. Este proyecto ejemplifica el enfoque de Sello Sol en promover prácticas económicas sostenibles y conscientes.",
      link: "https://gtime.cl/general/ogr_sabor_a_sol/",
    },
  ];

  const slides = [
    {
      title:
        "Generadores y consumidores conectados directamente, sin intermediarios",
      image: slide1,
    },
    {
      title: "Oferta tu generación, prospecta y conecta con clientes",
      image: slide2,
    },
    {
      title: "Subasta tu consumo, busca y conecta con proveedores energéticos",
      image: slide3,
    },
    {
      title:
        "Trazabilidad energética con certificados de origen para todos los agentes del mercado",
      image: slide4,
    },
    {
      title:
        "Generadores y consumidores conectados directamente, sin intermediarios",
      image: slide5,
    },
    {
      title: "Oferta tu generación, prospecta y conecta con clientes",
      image: slide6,
    },
    {
      title: "Subasta tu consumo, busca y conecta con proveedores energéticos",
      image: slide7,
    },
    {
      title:
        "Trazabilidad energética con certificados de origen para todos los agentes del mercado",
      image: slide8,
    },
  ];

  return (
    <div className="Home">
      <Slideshow slides={slides} />

      <Container>
        <div className="video-datos">
          <div className="video">
            <React.Fragment>
              <img
                className="video-preview"
                src="./placeholder/video.jpg"
                alt="Video"
                onClick={() => setIsVideoOpen(true)}
              />
              <ModalVideo
                channel="vimeo"
                autoplay="true"
                isOpen={isVideoOpen}
                videoId="872180811"
                onClose={() => setIsVideoOpen(false)}
              />
            </React.Fragment>
          </div>
          <div className="datos">
            <div className="box">
              <h2 className="mt-2">Nuestras cifras</h2>
              <Row className="mb-3">
                <Col>
                  <div className="dato bg-icono-paneles">
                    <div className="dato-titulo">Energía generada</div>
                    <div className="dato-valor">
                      {kwhToMwhStr(globalData["totalGenerated"])}{" "}
                      <span className="dato-unidad">MWh</span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="dato bg-icono-casa">
                    <div className="dato-titulo">Energía transada</div>
                    <div className="dato-valor">
                      {kwhToMwhStr(globalData["totalTransacted"])}{" "}
                      <span className="dato-unidad">MWh</span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="dato bg-icono-tierra">
                    <div className="dato-titulo">{mwhTokTonCO2eq(
                        globalData["totalTransacted"]/1000,
                        globalData["TONco2factor"]
                      )>=1000?"kTon":"Ton"}s Carbono desplazadas</div>
                    <div className="dato-valor">
                      {mwhTokTonCO2eq(
                        globalData["totalTransacted"]/1000,
                        globalData["TONco2factor"]
                      )>=1000?mwhTokTonCO2eq(
                        globalData["totalTransacted"]/1000,
                        globalData["TONco2factor"]
                      ):kwhToCo2TonStr(
                        globalData["totalTransacted"]/1000,
                        globalData["TONco2factor"]
                      )}{" "}
                      <span className="dato-unidad">{mwhTokTonCO2eq(
                        globalData["totalTransacted"]/1000,
                        globalData["TONco2factor"]
                      )>=1000?"kTon":"Ton"}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="box">
              <h2>Gráfico de energía trazada por GTIME Blockchain [MWh]</h2>
              <div className="grafico-container">
                <Line data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <section className="buscador">
        <div className="separador">
          <h3 className="separador-heading">
            Últimas transacciones realizadas
          </h3>
          <IconosTodos className="iconos" width={"25%"} />
        </div>
        <Container>
          <EnergyOrderCarousel count={6} />
          <div className="text-center mt-5">
            <Link to="/marketplace" className="btn btn-lg btn-marketplace">
              Acceder
            </Link>
          </div>
        </Container>
      </section>

      <div className="separador">
        <h3 className="separador-heading">
          Conoce más de las aplicaciones del blockchain de energía
        </h3>
        <IconosTodos className="iconos" width={"25%"} />
      </div>

      <section className="articles">
        <Container>
          <article className="primary">
            <Row>
              <Col>
                <Link to={articles[0].link} target="_blank">
                  <img
                    src={articles[0].image}
                    alt={articles[0].title}
                    className="img-fluid"
                  />
                </Link>
              </Col>
              <Col>
                <h2 className="mb-0">
                  <Link to={articles[0].link} target="_blank">
                    {articles[0].title}
                  </Link>
                </h2>
                <h3>
                  <Link to={articles[0].link} target="_blank">
                    {articles[0].subtitle}
                  </Link>
                </h3>
                <p
                  dangerouslySetInnerHTML={{ __html: articles[0].content }}
                ></p>
                <p>
                  <Link to={articles[0].link} target="_blank">
                    Saber más...
                  </Link>
                </p>
              </Col>
            </Row>
          </article>
          <div className="carrusel my-5">
            <Row>
              {articles.map((article, index) =>
                index > 0 ? (
                  <Col key={index}>
                    <article className="secondary">
                      <Link to={article.link} target="_blank">
                        <img
                          src={article.image}
                          alt={article.title}
                          className="img-fluid"
                        />
                      </Link>
                      <h2>
                        <Link to={article.link} target="_blank">
                          {article.title}
                        </Link>
                      </h2>
                      <p
                        className="text-justify"
                        dangerouslySetInnerHTML={{ __html: article.content }}
                      ></p>
                      <p>
                        <Link to={article.link} target="_blank">
                          Saber más...
                        </Link>
                      </p>
                    </article>
                  </Col>
                ) : null
              )}
            </Row>
          </div>
        </Container>
      </section>

      <div className="separador">
        <h3 className="separador-heading">
          Trabajamos junto a grandes instituciones y empresas
        </h3>
        <IconosTodos className="iconos" width={"25%"} />
      </div>

      <section className="logotipos">
        <Container className="text-center">
          <Image src="./placeholder/logos-inicio.png" fluid />
        </Container>
      </section>
    </div>
  );
};
export default Home;
