import React, { useState, useEffect, useContext } from "react";
import "./User.scss";

import Modal from "react-modal";
import { Container, Row, Col, Button, Table, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import Profile from "../../components/Profile/Profile";

import { UserContext } from "../../contexts/UserContext";
import GtimeApiService from "../../services/GtimeApiService";
import { kwhToMwhStr } from "../../helpers/helpers";
import EnergyIcon from "../../components/EnergyIcon/EnergyIcon";
const User = () => {
  const { client } = useContext(UserContext);

  const [transactions, setTransactions] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [copied, setCopied] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      const transactions = await GtimeApiService.getTransactions();
      setTransactions(transactions);
    };
    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchCertificates = async () => {
      const certificates = await GtimeApiService.getCertificates();
      setCertificates(certificates);
    };
    fetchCertificates();
  }, []);

  const openModal = (pdfUrl) => {
    setModalIsOpen(true);
    setPdfUrl(pdfUrl);
  };
  function copyToClipboard(value) {
    navigator.clipboard.writeText(value);
    setCopied(value);
    setTimeout(() => setCopied(null), 2000)
  }

  function truncateValue(value) {
    if (!value || value.length === 0) {
      return '';
    }
    if (value.length > 10) {
      return value.slice(0, 4) + '...' + value.slice(-3);
    }
    return value;
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setPdfUrl("");
  };

  if (!client) {
    return;
  }

  return (
    <div className="User">
      <Profile />

      <Container className="mb-5">
        <Row>
          <Col md={5}>
            <h3>Acción de Compra y Venta</h3>
          </Col>
          <Col md={7} className="text-end">
            <Link to="/energy-order/create-offer">
              <Button className="ms-2 mb-1">Quiero ofertar generación</Button>
            </Link>
            <Link to="/energy-order/create-demand">
              <Button className="ms-2 mb-1">Quiero subastar consumo</Button>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container>
        <div className="card shadow">
          <div className="card-body">
            <h4>Historial</h4>
            <p>Estas son tus transacciones energéticas.</p>

            <Table striped bordered hover className="text-center">
              <thead style={{ backgroundColor: "#21c060", color: "white" }}>
                <tr>
                  <th>Wallet Origen</th>
                  <th>CID IPFS</th>
                  <th>Energía</th>
                  <th className="text-end" width="10%">
                    [MWh]
                  </th>
                  <th className="text-center">Fecha</th>
                  <th className="text-center">Hora</th>
                  <th width="10%">Compra/Venta</th>
                  <th className="text-center">Transacción</th>
                  <th className="text-center">Certificado</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => {
                  const walletsIds = client.wallets.map(
                    (wallet) => wallet.walletId
                  );
                  if (
                    walletsIds.includes(transaction.origin.walletId) ||
                    walletsIds.includes(transaction.destiny.walletId)
                  ) {
                    console.log(transaction.status)
                    return (
                      <tr key={index}>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${index}`} >
                                {copied === transaction.origin.walletId ? 'Copiado!' : transaction.origin.walletId}
                              </Tooltip>
                            }
                          >
                            <span className="truncated-value"
                              onClick={() => copyToClipboard(transaction.origin.walletId)}>
                              {
                                truncateValue(transaction.origin.walletId)
                              }
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td>
                          {transaction.status === 'processing' ? <Spinner animation="grow" variant="success" /> : <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${index}`} >
                                {copied === transaction.transactionData.ipfsPin ? 'Copiado!' : transaction.transactionData.ipfsPin}
                              </Tooltip>
                            }
                          >
                            <span className="truncated-value"
                              onClick={() => copyToClipboard(transaction.transactionData.ipfsPin)}>
                              {
                                truncateValue(transaction.transactionData.ipfsPin)
                              }
                            </span>
                          </OverlayTrigger>}

                        </td>
                        <td className="text-nowrap">
                          {transaction.certificate && (
                            <>
                              <EnergyIcon
                                width={20}
                                height={20}
                                energySource={
                                  transaction.certificate.energySource
                                }
                              />{" "}
                              {transaction.certificate?.energySource}
                            </>
                          )}
                        </td>
                        <td className="text-end">
                          {kwhToMwhStr(transaction.energy)}
                        </td>
                        <td className="text-center">
                          {moment(transaction.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td className="text-center">
                          {moment(transaction.createdAt).format("HH:mm")}
                        </td>
                        <td className="text-center">
                          {transaction.transactionType === "Consumption"
                            ? "Compra"
                            : "Venta"}
                        </td>
                        <td className="text-center">
                          {transaction ? (
                            <Button
                              size="sm"
                              onClick={() =>
                                window.open(
                                  `https://amoy.polygonscan.com/tx/${transaction.transactionData.chainTrx}`,
                                  "_blank"
                                )
                              }
                            >
                              Verificar
                            </Button>
                          ) : (
                            "En proceso..."
                          )}
                        </td>
                        <td className="text-center">
                          {transaction ? (
                            <Button
                              size="sm"
                              onClick={() =>
                                openModal(transaction.certificate.IPFSpath)
                              }
                            >
                              Ver
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  }
                  return "";
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <embed
          src={pdfUrl}
          width="100%"
          height="90%"
          type="application/pdf"
          style={{ width: "100%", height: "90%" }}
        />
        <Button
          style={{ position: "absolute", bottom: "15px", right: "20px" }}
          onClick={closeModal}
        >
          Cerrar
        </Button>
      </Modal>
    </div>
  );
};
export default User;
