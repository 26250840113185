import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import SearchBox from "../../components/SearchBox/SearchBox";
import Slideshow from "../../components/Slideshow/Slideshow";

import slide1 from "../../assets/slider/slide-5.jpg";

const Marketplace = () => {
  const slides = [
    {
      title: "Buscador energético",
      image: slide1,
    },
  ];

  useEffect(() => {
    const slideItemImg = document.querySelector(".Slideshow .slide-item img");
    if (slideItemImg) {
      slideItemImg.style.height = "25vh";
    }
  }, []);

  return (
    <div className="Marketplace">
      <Slideshow slides={slides} />

      <section className="buscador">
        <Container>
          <SearchBox></SearchBox>
        </Container>
      </section>
    </div>
  );
};
export default Marketplace;
