import React from 'react';
import './Slideshow.scss';

import { Container } from 'react-bootstrap';
import Slider from 'react-slick';

const Slideshow = (props) => {

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,
    autoplay: true,
    fade: true,
  };

  return (
    <div className="Slideshow">
      <div className="slider">
        <Slider {...sliderSettings}>
          {props.slides.map((slide, index) => (
            <div className="slide-item" key={index}>
              <img src={slide.image} alt={slide.title} />
              <div className="slide-content">
                <Container>
                  <div className="heading">{slide.title}</div>
                </Container>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

Slideshow.propTypes = {};

Slideshow.defaultProps = {};

export default Slideshow;
