import React from "react";
import "./SiteNavbar.scss";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/logo-gtime.svg";
import { ReactComponent as IconUser } from "../../assets/icono-user.svg";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signOut } from "../../services/firebase";

const SiteNavbar = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut(auth).then(() => {
      navigate("/");
    });
  };
  return (
    <div className="SiteNavbar">
      <Navbar bg="primary" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="https://gtime.cl">
            <Logo height={40}></Logo>
          </Navbar.Brand>
          <Navbar.Toggle style={{ borderColor: "white", color: "white" }} />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="ml-auto">
              <Nav.Link href="https://gtime.cl">Inicio</Nav.Link>
              <Nav.Link href="https://gtime.cl/nosotros/">Nosotros</Nav.Link>
              <Nav.Link href="https://gtime.cl/que-hacemos/">
                Qué hacemos
              </Nav.Link>
              <Nav.Link href="https://gtime.cl/tecnologia/">
                Tecnología
              </Nav.Link>
              <Nav.Link href="https://gtime.cl/marketplace/">
                Marketplace
              </Nav.Link>
              <Nav.Link href="https://gtime.cl/blog/">Blog</Nav.Link>
              <Nav.Link href="https://gtime.cl/contactanos/">
                Contáctanos
              </Nav.Link>
              {user ? (
                <NavDropdown
                  title={
                    <span title={user.email}>
                      <IconUser height={14} />{" "}
                      <span>
                        {user.email.length > 20
                          ? user.email.slice(0, 20) + "..."
                          : user.email}
                      </span>
                    </span>
                  }
                  id="user-dropdown"
                >
                  <LinkContainer to="/">
                    <NavDropdown.Item>Home</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/marketplace">
                    <NavDropdown.Item>Buscador Energético</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/energy-order/create-offer">
                    <NavDropdown.Item>Ofertar generación</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/energy-order/create-demand">
                    <NavDropdown.Item>Subastar consumo</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/usuario">
                    <NavDropdown.Item>Mi Perfil</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/usuario/datos">
                    <NavDropdown.Item>Mis Datos de Cliente</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/usuario/cambiar-contrasena">
                    <NavDropdown.Item>Cambiar Contraseña</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>
                    <b>Cerrar Sesión</b>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/usuario">
                  <Nav.Link>
                    <IconUser height={14} />{" "}
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

SiteNavbar.propTypes = {};

SiteNavbar.defaultProps = {};

export default SiteNavbar;
