import React from "react";

import "./EnergyIcon.scss";

import IconoEolica from "../../assets/icono-energia-eolica.svg";
import IconoSolar from "../../assets/icono-energia-solar.svg";
import IconoHidrica from "../../assets/icono-energia-maritima.svg";
import IconoElectrica from "../../assets/icono-energia-electrica.svg";
import IconoCalorica from "../../assets/icono-energia-calorica.svg";
import IconoCombustible from "../../assets/icono-energia-combustible.svg";
import IconoMineral from "../../assets/icono-energia-mineral.svg";

const EnergyIcon = (props) => {
  const energyIcons = {
    Wind: IconoEolica,
    Solar: IconoSolar,
    Hydro: IconoHidrica,
    BioMasa: IconoElectrica,
    Thermal: IconoCalorica,
    Gas: IconoCombustible,
    Coal: IconoMineral,
  };

  return energyIcons[props.energySource] ? (
    <img
      className="EnergyIcon"
      src={energyIcons[props.energySource]}
      alt={props.energySource}
      width={props.width}
      height={props.height}
    />
  ) : (
    ""
  );
};

EnergyIcon.propTypes = {};

EnergyIcon.defaultProps = {
  width: 50,
  height: 50,
};

export default EnergyIcon;
