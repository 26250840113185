import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_AUTH_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_AUTH_PI,
    storageBucket: process.env.REACT_APP_AUTH_SB,
    messagingSenderId: process.env.REACT_APP_AUTH_MSI,
    appId: process.env.REACT_APP_AUTH_AI,
    measurementId: process.env.REACT_APP_AUTH_MI
  };
const app = initializeApp(firebaseConfig);
const auth = getAuth();

export { app, auth, signOut };
