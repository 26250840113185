import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Col, Form, Row, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import GtimeApiService from "../../services/GtimeApiService";
import { useAuthState } from "react-firebase-hooks/auth";

const Login = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [operators, setOperators] = useState([]);
  const [operatorId, setoperatorId] = useState(0);
  const [isSigninIn, setIsSigninIn] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/usuario");
    }
  }, [user, navigate]);

  useEffect(() => {
    getOperators();
    async function getOperators() {
      const operators = await GtimeApiService.getOperators();
      setOperators(operators);
    }
  }, []);

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    console.log("validating", email, emailRegex.test(email));
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const { email, password, confirmPassword } = formData;
    let newForm = { ...formData, operatorId: operatorId };
    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "La contraseña y su confirmación no coinciden",
      });
      return;
    }

    if (!isValidEmail(email)) {
      Swal.fire({
        icon: "error",
        title: "Ingresa un correo electrónico válido",
      });
      return;
    }
    const createClientRes = await GtimeApiService.createClient(newForm);
    if (createClientRes) {
      Swal.fire({
        icon: "success",
        title: "Cliente registrado con éxito",
      });
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSigninIn(true);

    await signInWithEmailAndPassword(
      auth,
      emailRef?.current?.value,
      passwordRef?.current?.value
    )
      .then((userCredential) => {
        console.log("CRED", userCredential);
        navigate("/usuario");
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Datos incorrectos",
          text: "El correo electrónico o la contraseña son incorrectos.",
          confirmButtonText: "Aceptar",
        });
      })
      .finally(() => {
        setIsSigninIn(false);
      });
  };

  return (
    <div className="Login">
      <Container>
        <Row>
          <Col>
            <div className="card bg-light shadow my-5">
              <div className="card-body">
                <h2 className="mb-4">Iniciar sesión</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      required
                      ref={emailRef}
                    />
                    <Form.Text className="text-muted">
                      Nunca compartiremos tu correo con nadie más.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                      ref={passwordRef}
                    />
                  </Form.Group>
                  <Button
                    className="w-100"
                    variant="success"
                    type="submit"
                    disabled={isSigninIn}
                  >
                    {isSigninIn ? "Cargando..." : "Iniciar sesión"}
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card bg-light shadow my-5">
              <div className="card-body">
                <h2 className="mb-4">Registro</h2>
                <p>
                  Ingresa los siguientes datos para conocer la plataforma,
                  recibirás una confirmación en tu correo.
                </p>

                <Form onSubmit={handleRegister}>
                  <input
                    id="name"
                    name="name"
                    className="form-control mb-2"
                    type="text"
                    placeholder="NOMBRE"
                    onChange={handleChange}
                  />
                  <input
                    id="email"
                    name="email"
                    className="form-control mb-2"
                    type="text"
                    placeholder="EMAIL"
                    onChange={handleChange}
                  />
                  <p>
                    Ingresa tu contraseña incorporando mayúscula, números y
                    símbolos.
                  </p>
                  <input
                    id="password"
                    name="password"
                    className="form-control mb-2"
                    type="password"
                    placeholder="CONTRASEÑA"
                    onChange={handleChange}
                  />
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    className="form-control mb-2"
                    type="password"
                    placeholder="CONFIRMA CONTRASEÑA"
                    onChange={handleChange}
                  />
                  <p>Elige tu operador.</p>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {operatorId === 0
                        ? "Operadores disponibles"
                        : operators.map((operator) =>
                            operator.id === operatorId ? operator.name : ""
                          )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {operators.length > 0 ? (
                        operators.map((operator) => (
                          <Dropdown.Item
                            key={operator.id}
                            onClick={() => setoperatorId(operator.id)}
                          >
                            {operator.name}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <></>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    type="submit"
                    variant="secondary"
                    className="w-100 my-3"
                  >
                    Regístrate
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Login;
