import { Button, Container } from "react-bootstrap";
import "./Validation.scss";
import IconoValidacion from "./../../assets/icono-validacion.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import GtimeApiService from "../../services/GtimeApiService";
import moment from "moment";

const Validation = () => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [validationData, setValidationData] = useState(null);

  useEffect(() => {
    if (!hash) {
      navigate("/");
    }
  }, [hash, navigate]);

  useEffect(() => {
    document.body.style.overflow = isLoading ? "hidden" : "auto";
  }, [isLoading]);

  useEffect(() => {
    if (!hash) {
      return;
    }

    const fetchValidationData = async (hash) => {
      const data = await GtimeApiService.getValidationData(hash);
      if (Array.isArray(data) && data.length > 0) {
        setValidationData(data[0]);
      }
      setIsLoading(false);
    };

    fetchValidationData(hash);
  }, [hash]);

  if (isLoading) {
    return (
      <div className="preloader-overlay">
        <div className="preloader-spinner"></div>
      </div>
    );
  }

  if (!isLoading && !validationData)
    return (
      <div className="Validation">
        <Container>
          <div className="validation-header">
            <h1>Certificado no encontrado</h1>
          </div>
        </Container>
      </div>
    );

  return (
    <div className="Validation">
      <Container>
        <div className="validation-header">
          <img src={IconoValidacion} alt="" className="img-fluid" />
          <h1>Certificado validado</h1>
        </div>
        <div className="validation-description">
          La información de este certificado se encuentra validado con
          tecnología <span>blockchain</span> en una red pública{" "}
          <span>Polygon</span> y el documento se encuentra en el sistema de
          archivos Interplanetario <span>IPFS</span>.
        </div>
        <div className="validation-data px-4 py-3">
          <h3>Information</h3>
          <dl>
            <dt>Energy [kWh]</dt>
            <dd>
              {validationData.energyAmount
                ? validationData.energyAmount.toLocaleString("es-CL")
                : ""}
            </dd>
            <dt>Date UTC</dt>
            <dd>
              From:{" "}
              {moment(validationData.dateFrom).format("DD/MM/YYYY HH:mm:ss")}{" "}
              To: {moment(validationData.dateTo).format("DD/MM/YYYY HH:mm:ss")}
            </dd>
            <dt>IPFS Path</dt>
            <dd>
              <Link to={validationData.IPFSpath}>
                {validationData.IPFSpath}
              </Link>
            </dd>
            <dt>Polygon MainNet transacion</dt>
            <dd>{validationData.mainNetTrx}</dd>
            <dt>GTIME hash</dt>
            <dd>{hash}</dd>
          </dl>
        </div>
        <div className="validation-cta">
          <Button
            as="a"
            href={`https://mumbai.polygonscan.com/tx/${validationData.mainNetTrx}`}
            target="_blank"
            variant="primary"
          >
            Blockchain validation
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Validation;
