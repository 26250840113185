import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

const PrivateRoute = ({ children }) => {
  const { isLoadingUserData, user } = useContext(UserContext);

  if (isLoadingUserData) {
    return "Loading..."; // O puedes retornar tu spinner de carga aquí
  }

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: window.location } }} />
    );
  }

  return children;
};

export default PrivateRoute;
