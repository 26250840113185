import React, { createContext, useState, useEffect } from 'react';
import GtimeApiService from "../services/GtimeApiService";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState({
    totalGenerated: -1,
    totalConsumed: -1,
    totalTransacted: -1,
    TONco2factor: 0.000371,
    monthlyEnergy: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    const fetchGlobalData = async () => {
      const homeData = await GtimeApiService.getHomeData();
      setGlobalData({
        ...homeData,
        monthlyEnergy: homeData.monthlyEnergy.map((energy) => energy / 1000),
      });
    };
    fetchGlobalData();
  }, []);

  return (
    <GlobalContext.Provider value={globalData}>
      {children}
    </GlobalContext.Provider>
  );
};