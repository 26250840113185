import React, { useContext } from "react";
import PropTypes from "prop-types";

import "./EnergyOrder.scss";

import { Image, Row, Col } from "react-bootstrap";
import { GlobalContext } from "../../contexts/GlobalContext";

import { kwhToMwhStr, kwhToCo2TonStr } from "../../helpers/helpers";
import EnergyIcon from "../EnergyIcon/EnergyIcon";

const EnergyOrder = ({ energyOrder, title, image, location }) => {
  const globalData = useContext(GlobalContext);

  return (
    <div className="EnergyOrder">
      <Image className="company-image" src={image} fluid />
      <Row>
        <Col md={2}>
          <EnergyIcon energySource={energyOrder.energySource} />
        </Col>
        <Col md={10}>
          <h3 title={title}>{title}</h3>
          <p>{location}</p>
        </Col>
      </Row>

      <Row className="company-data">
        <Col>
          <div className="data">
            {kwhToMwhStr(energyOrder.energyAmount)} MWh
          </div>
          <div className="data-title">Energía sustentable</div>
        </Col>
        {energyOrder.type === "energyOffer" ? (
          <Col>
            <div className="data">
              -
              {kwhToCo2TonStr(
                energyOrder.energyAmount,
                globalData["TONco2factor"]
              )}{" "}
              Ton
            </div>
            <div className="data-title">
              CO<sub>2</sub> en el ambiente
            </div>
          </Col>
        ) : (
          energyOrder.type === "energyDemand" && (
            <Col>
              <div className="data">${energyOrder.CLPperKWh} CLP/KWh</div>
              <div className="data-title">Precio por KWh</div>
            </Col>
          )
        )}
      </Row>
    </div>
  );
};

EnergyOrder.propTypes = {
  energyOrder: PropTypes.object,
  title: PropTypes.string,
  image: PropTypes.string,
  location: PropTypes.string,
};

EnergyOrder.defaultProps = {};

export default EnergyOrder;
