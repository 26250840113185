import React, { useState, useContext } from "react";
import "./CreateEnergyOffer.scss";

import {
  Row,
  Col,
  Popover,
  OverlayTrigger,
  Form,
  InputGroup,
  Button,
  Container,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IconoAyuda } from "../../../assets/icono-ayuda.svg";
import Profile from "../../../components/Profile/Profile";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { UserContext } from "../../../contexts/UserContext";
import { kwhToCo2TonStr, kwhToMwhStr } from "../../../helpers/helpers";
import Swal from "sweetalert2";
import GtimeApiService from "../../../services/GtimeApiService";
import moment from "moment";
import EnergyIcon from "../../../components/EnergyIcon/EnergyIcon";
import Datetime from "react-datetime";

const CreateEnergyOffer = () => {
  const globalData = useContext(GlobalContext);
  const { client, wallet } = useContext(UserContext);
  const navigate = useNavigate();

  const [energySource, setEnergySource] = useState("Wind");
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [energyAmount, setEnergyAmount] = useState(0);
  const [clpPerKwh, setClpPerKwh] = useState(0);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const popoverMwh = (
    <Popover>
      <Popover.Body>
        Energía sustentable disponible para la venta en MWh.
      </Popover.Body>
    </Popover>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (energyAmount === "") {
      Swal.fire({
        icon: "warning",
        text: "Debes ingresar la cantidad de energía de esta oferta.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    if (clpPerKwh === "") {
      Swal.fire({
        icon: "warning",
        text: "Debes ingresar el precio unitario por kWh.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    const newEnergyOrder = {
      type: "energyOffer",
      energyAmount: parseInt(energyAmount),
      walletId: wallet.id,
      CLPperKWh: parseInt(clpPerKwh),
      dateFrom: moment(startDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.00"),
      dateTo: moment(endDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.00"),
      energySource: energySource,
    };

    setIsCreatingOrder(true);

    try {
      await GtimeApiService.createEnergyOrder(newEnergyOrder);
      setIsCreatingOrder(false);

      const ok = await Swal.fire({
        title: "Oferta Creada",
        text: "La Oferta de Generación de Energía fue creada exitosamente.",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
      if (ok.isConfirmed) {
        navigate("/usuario");
      }
    } catch (error) {
      console.error("ERROR CREANDO ENERGYORDER: ", error);
      setIsCreatingOrder(false);
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al crear la Oferta de Generación de Energía. Intente otra vez.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }

    return;
  };

  return (
    <div className="CreateEnergyOffer">
      <Profile />

      <Container className="mt-5">
        <h1>Ofertar Generación de Energía</h1>
        <p>Mi Energía disponible para la venta</p>

        <Form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="PurchaseOrder">
                <Row>
                  <Col xs={3}>
                    <div className="data-head">{client.name}</div>
                    <div className="data-description">{client.address}</div>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      {kwhToMwhStr(0)} MWh
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popoverMwh}
                      >
                        <IconoAyuda height={15} className="mx-1" />
                      </OverlayTrigger>
                    </div>
                    <div className="data-description">Energía Sustentable</div>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      {kwhToCo2TonStr(0, globalData["TONco2factor"])} Ton
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popoverMwh}
                      >
                        <IconoAyuda height={15} className="mx-1" />
                      </OverlayTrigger>
                    </div>
                    <div className="data-description">
                      CO<sub>2</sub> en el ambiente
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      ${(0).toLocaleString("es-CL")}
                    </div>
                    <div className="data-description text-uppercase">Total</div>
                    <p></p>
                  </Col>
                </Row>

                <hr />

                <Row>
                  <Col xs={8}>
                    <p>Selecciona la fuente energética que quieres ofertar.</p>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        className="radio-vertical-center"
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Wind"
                        value="Wind"
                        checked={energySource === "Wind"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Wind">
                        <EnergyIcon energySource="Wind" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Solar"
                        value="Solar"
                        checked={energySource === "Solar"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Solar">
                        <EnergyIcon energySource="Solar" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Hydro"
                        value="Hydro"
                        checked={energySource === "Hydro"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Hydro">
                        <EnergyIcon energySource="Hydro" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-BioMasa"
                        value="BioMasa"
                        checked={energySource === "BioMasa"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-BioMasa">
                        <EnergyIcon energySource="BioMasa" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Thermal"
                        value="Thermal"
                        checked={energySource === "Thermal"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Thermal">
                        <EnergyIcon energySource="Thermal" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Gas"
                        value="Gas"
                        checked={energySource === "Gas"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Gas">
                        <EnergyIcon energySource="Gas" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Coal"
                        value="Coal"
                        checked={energySource === "Coal"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Coal">
                        <EnergyIcon energySource="Coal" />
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col>
                    <Form.Label>Desde</Form.Label>
                    <Datetime
                      locale="es"
                      onChange={(dateTime) => setStartDateTime(dateTime)}
                      value={startDateTime}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Hasta</Form.Label>
                    <Datetime
                      locale="es"
                      onChange={(dateTime) => setEndDateTime(dateTime)}
                      value={endDateTime}
                    />
                  </Col>
                </Row>

                <hr />

                <Row className="mt-3">
                  <Col xs={3} className="text-end text-uppercase">
                    <small>Cantidad de Energía a Ofertar</small>
                  </Col>
                  <Col xs={3}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        id="energyAmount"
                        name="energyAmount"
                        placeholder=""
                        step="1"
                        min={1}
                        max={999999999}
                        value={energyAmount}
                        onChange={(event) =>
                          setEnergyAmount(event.target.value)
                        }
                      />
                      <InputGroup.Text>kWh</InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      -
                      {kwhToCo2TonStr(energyAmount, globalData["TONco2factor"])}{" "}
                      Ton
                    </div>
                    <div className="data-description">
                      CO<sub>2</sub> en el ambiente
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      ${(energyAmount * clpPerKwh).toLocaleString("es-CL")}
                    </div>
                    <div className="data-description">TOTAL OFERTA</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} className="text-end text-uppercase">
                    <small>Precio unitario oferta</small>
                  </Col>
                  <Col xs={3}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        id="clpPerKwh"
                        name="clpPerKwh"
                        placeholder=""
                        min={1}
                        value={clpPerKwh}
                        onChange={(event) => setClpPerKwh(event.target.value)}
                      />
                      <InputGroup.Text>CLP/kWh</InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Row className="my-3">
            <Col>
              <Link to="/usuario">
                <Button variant="secondary">Cancelar</Button>
              </Link>
            </Col>
            <Col className="text-end">
              <Button
                type="submit"
                variant="primary"
                disabled={isCreatingOrder}
              >
                {isCreatingOrder ? "Cargando..." : "Ofertar generación"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
export default CreateEnergyOffer;
