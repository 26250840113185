import React, { useState, useContext } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as Flechas } from "../../assets/icono-flechas-avanzar.svg";
import { kwhToMwhStr } from "../../helpers/helpers";
import GtimeApiService from "../../services/GtimeApiService";
import { UserContext } from "../../contexts/UserContext";

const ValidationCodeForm = ({
  onIsValidCodeChange,
  seller,
  buyer,
  newEnergyOrder,
  energyOrderId,
  type,
}) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [loadingCodeVerification, setLoadingCodeVerification] = useState(false);
  const isCodeComplete = code.every((digit) => digit !== "");
  const { user } = useContext(UserContext);

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    if (!/^\d*$/.test(value) || value.length > 1) {
      return;
    }

    const newCode = [...code];
    newCode[index] = value;

    if (value) {
      const nextInput = event.target.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    }

    setCode(newCode);
  };

  const handleSubmitCode = async (event) => {
    event.preventDefault();
    setIsValidatingCode(true);
    const token = await user.getIdToken(true);
    const fullCode = code.join("");
    // TODO Validar código con API.
    // await GtimeApiService.setNegotiation(Number(energyOrderId),fullCode,user.email);
    const codeResponse = await GtimeApiService.verifyCode(fullCode, token);
    const isValidCode = codeResponse === fullCode ? true : false;
    onIsValidCodeChange(isValidCode);
    setIsValidatingCode(false);
  };
  const handleObtainCode = async () => {
    setLoadingCodeVerification(true);
    const token = await user.getIdToken(true);
    const response = await fetch(
      "https://api.gtime.cl/marketplace-dev/getCode",
      {
        method: "POST",
        headers: {
          Authorization: token,
        },
      }
    );
    if (response) {
      setLoadingCodeVerification(false);
    }
  };
  return (
    <div className="ValidationCodeForm">
      <h1>Emisión de Certificado NFT</h1>
      <p>Resumen de la transacción.</p>
      <Form onSubmit={handleSubmitCode}>
        <div className="card">
          <div className="card-body">
            <Row className="mb-4">
              <Col xs={5}>
                <div className="data-description">Empresa Vendedora</div>
                <div className="data-head text-uppercase">{seller.name}</div>
              </Col>
              <Col xs={2} className="text-center">
                <Flechas height={40} />
              </Col>
              <Col xs={5}>
                <div className="data-description">Empresa Compradora</div>
                <div className="data-head text-uppercase">{buyer.name}</div>
              </Col>
            </Row>

            <hr />

            <Row className="mb-4">
              <Col xs={5}>
                <div className="data-description">
                  Energía Sustentable Vendida
                </div>
                <div className="data-head">
                  {kwhToMwhStr(newEnergyOrder.energyAmount)} MWh producidos por{" "}
                  {seller.name} entre entre{" "}
                  {new Date(newEnergyOrder.dateFrom).toLocaleString("es-CL")} y{" "}
                  {new Date(newEnergyOrder.dateTo).toLocaleString("es-CL")}
                </div>
              </Col>
              <Col xs={2}></Col>
              <Col xs={5}>
                <div className="data-description">Monto Transacción</div>
                <div className="data-head text-uppercase">
                  $
                  {(
                    newEnergyOrder.energyAmount * newEnergyOrder.CLPperKWh
                  ).toLocaleString("es-CL")}
                </div>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col xs={6}>
                <Button
                  onClick={handleObtainCode}
                  disabled={loadingCodeVerification}
                >
                  {!loadingCodeVerification ? (
                    "Obtener Código"
                  ) : (
                    <Spinner></Spinner>
                  )}
                </Button>
                <p>Ingreso de clave de DigiPass</p>
                <div className="d-flex justify-content-center">
                  {code.map((digit, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      pattern="[0-9]*"
                      maxLength="1"
                      className="mx-2"
                      value={digit}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Link to="/marketplace">
                  <Button variant="secondary">Cancelar</Button>
                </Link>
              </Col>
              <Col className="text-end">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!isCodeComplete}
                >
                  {isValidatingCode
                    ? "Cargando..."
                    : type === "sell"
                    ? "Vender Certificado NFT"
                    : "Comprar Certificado NFT"}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
};

ValidationCodeForm.propTypes = {
  onIsValidCodeChange: PropTypes.func.isRequired,
};

ValidationCodeForm.defaultProps = {};

export default ValidationCodeForm;
