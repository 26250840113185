import React, { useState, useEffect } from "react";

import "./SellFinish.scss";

import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import GtimeApiService from "../../services/GtimeApiService";

import Profile from "../../components/Profile/Profile";

import { ReactComponent as Logo } from "../../assets/logo-gtime.svg";

const SellFinish = () => {
  const [negotiation, setNegotiation] = useState(null);
  const [isLoadingNegotiation, setIsLoadingNegotiation] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchNegotiation = async () => {
      try {
        const negotiation = await GtimeApiService.getNegotiation(id);
        setNegotiation(negotiation);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingNegotiation(false);
      }
    };
    fetchNegotiation();
  }, [id]);

  if (isLoadingNegotiation) {
    return (
      <div className="preloader-overlay">
        <div className="preloader-spinner"></div>
      </div>
    );
  }

  return (
    <div className="SellFinish">
      <Profile />

      <Container>
        <div className="card shadow">
          <div className="card-body">
            <div className="logo mb-3 p-3 bg-black text-center">
              <Logo height={50} />
            </div>

            {negotiation ? (
              <>
                <h3 className="text-center my-5">
                  ¡Felicidades, realizaste tu venta!
                </h3>
                <p className="text-center mb-5">
                  Estamos completando tu transacción. En unos minutos podrás
                  descargar el certificado en el historial de transacciones de
                  tu perfil.
                </p>
              </>
            ) : (
              <>
                <h3 className="text-center my-5">Ocurrió un error</h3>
                <p className="text-center mb-5">
                  No se pudo completar la transacción. Intenta otra vez o
                  contáctanos si tienes problemas.
                </p>
              </>
            )}

            <hr />
            <Row className="my-4">
              <Col>
                <Button as={Link} to="/marketplace" variant="primary">
                  Volver al Marketplace
                </Button>
              </Col>
              <Col className="text-end">
                <Button
                  as={Link}
                  to="/usuario"
                  variant="primary"
                  className="text-end"
                >
                  Ir a mi perfil
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default SellFinish;
