import React from "react";

import "./App.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";

import { Container } from "react-bootstrap";

import { GlobalContextProvider } from "./contexts/GlobalContext";
import { UserContextProvider } from "./contexts/UserContext";

import SiteNavbar from "./components/SiteNavbar/SiteNavbar";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";

import Login from "./pages/Login/Login";
import User from "./pages/User/User";
import UpdateUser from "./pages/User/UpdateUser/UpdateUser";
import CreateEnergyOffer from "./pages/EnergyOrder/EnergyOffer/CreateEnergyOffer";
import CreateEnergyDemand from "./pages/EnergyOrder/EnergyDemand/CreateEnergyDemand";

import Marketplace from "./pages/Marketplace/Marketplace";
import Purchase from "./pages/Purchase/Purchase";
import PurchaseFinish from "./pages/PurchaseFinish/PurchaseFinish";
import Sell from "./pages/Sell/Sell";
import SellFinish from "./pages/SellFinish/SellFinish";
import ChangePassword from "./pages/User/ChangePassword/ChangePassword";
import Validation from "./pages/Validation/Validation";

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <BrowserRouter>
          <GlobalContextProvider>
            <div className="App">
              <SiteNavbar></SiteNavbar>

              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/login" element={<Login />} />
                <Route
                  exact
                  path="/marketplace"
                  element={
                    <PrivateRoute>
                      <Marketplace />
                    </PrivateRoute>
                  }
                />
                <Route exact path="/quienes-somos" element={<About />} />
                <Route
                  exact
                  path="/purchase/:id"
                  element={
                    <PrivateRoute>
                      <Purchase />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/purchase/finish/:id?"
                  element={
                    <PrivateRoute>
                      <PurchaseFinish />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/sell/:id"
                  element={
                    <PrivateRoute>
                      <Sell />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/sell/finish/:id?"
                  element={
                    <PrivateRoute>
                      <SellFinish />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/usuario"
                  element={
                    <PrivateRoute>
                      <User />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/usuario/datos"
                  element={
                    <PrivateRoute>
                      <UpdateUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/usuario/cambiar-contrasena"
                  element={
                    <PrivateRoute>
                      <ChangePassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/energy-order/create-offer"
                  element={
                    <PrivateRoute>
                      <CreateEnergyOffer />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/energy-order/create-demand"
                  element={
                    <PrivateRoute>
                      <CreateEnergyDemand />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/validador/:hash?"
                  element={<Validation />}
                />
              </Routes>

              <footer className="site-footer">
                <Container>
                  <div className="footer-info">Powered by phiNet®</div>
                </Container>
              </footer>
            </div>
          </GlobalContextProvider>
        </BrowserRouter>
      </UserContextProvider>
    </div>
  );
}

export default App;
