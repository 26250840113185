import React, { useState, useContext, useEffect } from "react";
import "./UpdateUser.scss";

import { Container, Form, Button } from "react-bootstrap";
import Profile from "../../../components/Profile/Profile";
import { UserContext } from "../../../contexts/UserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../services/firebase";
import GtimeApiService from "../../../services/GtimeApiService";
import Swal from "sweetalert2";

const UpdateUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOp, setIsLoadingOp] = useState(false);
  const client = useContext(UserContext).client;
  const [values, setValues] = useState(client);
  const [valuesOp, setValuesOp] = useState(client?.operator?client.operator:{});
  const [companyImage, setCompanyImage] = useState(null);
  const [isOperatorAdmin, setIsOperatorAdmin] = useState(false);
  const [companyImageOp, setCompanyImageOp] = useState(null);

  useEffect(() => {
    if (client) {
      if (client.operator.email === client.email) {
        console.log(client.operator)
        setIsOperatorAdmin(true);
        console.log("Cliente Operador ADMIN");
      }
    }
  }, [client])


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCompanyImage(file);
  };
  const handleImageChangeOp = (e) => {
    const file = e.target.files[0];
    setCompanyImageOp(file);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeOp = (event) => {
    setValuesOp({
      ...valuesOp,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await GtimeApiService.updateClient(client.id, values, companyImage).then(
        () => {
          setIsLoading(false);
        }
      );
      Swal.fire({
        icon: "success",
        title: "Datos personales actualizados",
      });
      console.log("Usuario actualizado correctamente");
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error al actualizar los datos personales",
        text: error.message,
      });
      console.error("Error al actualizar el usuario:", error);
    }
  };

  const handleSubmitOp = async (event) => {
    event.preventDefault();
    setIsLoadingOp(true);
    try {
      await GtimeApiService.updateOperator(client.operator.id, valuesOp, companyImageOp).then(
        () => {
          setIsLoadingOp(false);
        }
      );
      Swal.fire({
        icon: "success",
        title: "Datos personales actualizados",
      });
      console.log("Usuario actualizado correctamente");
    } catch (error) {
      setIsLoadingOp(false);
      Swal.fire({
        icon: "error",
        title: "Error al actualizar los datos personales",
        text: error.message,
      });
      console.error("Error al actualizar el usuario:", error);
    }
  };
  return (
    <div className="UpdateUser">
      <Profile />
      <Container>
        <div className="card shadow my-5">
          <div className="card-body">
            <h2 className="mb-4">Mis Datos de Cliente</h2>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formCompanyName" className="mb-3">
                <Form.Label>Nombre de la Empresa</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formCompanyDescription" className="mb-3">
                <Form.Label>Descripción de la Empresa</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Región</Form.Label>
                <Form.Select
                  defaultValue={values.region}
                  name="region"
                  onChange={handleChange}
                  required
                >
                  <option value="Arica y Parinacota">Arica y Parinacota</option>
                  <option value="Tarapacá">Tarapacá</option>
                  <option value="Antofagasta">Antofagasta</option>
                  <option value="Atacama">Atacama</option>
                  <option value="Coquimbo">Coquimbo</option>
                  <option value="Valparaíso">Valparaíso</option>
                  <option value="Metropolitana">Metropolitana</option>
                  <option value="O’Higgins">O’Higgins</option>
                  <option value="Maule">Maule</option>
                  <option value="Ñuble">Ñuble</option>
                  <option value="Biobío">Biobío</option>
                  <option value="Araucanía">Araucanía</option>
                  <option value="Los Ríos">Los Ríos</option>
                  <option value="Los Lagos">Los Lagos</option>
                  <option value="Aysén">Aysén</option>
                  <option value="Magallanes">Magallanes</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ciudad</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ubicación</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formCompanyImage" className="mb-3">
                {values.image ? (
                  <div>
                    <img src={values.image} alt="" height={100} />
                  </div>
                ) : (
                  ""
                )}
                <Form.Label>Imagen de la Empresa</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? "Cargando..." : "Guardar Cambios"}
              </Button>
            </Form>
          </div>
        </div>
        {isOperatorAdmin?<div className="card shadow my-5">
          <div className="card-body">
          <h2 className="mb-4">Mis Datos de Operator</h2>
          <Form onSubmit={handleSubmitOp}>
              <Form.Group controlId="formCompanyName" className="mb-3">
                <Form.Label>Nombre del Operador Eléctrico</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={valuesOp.name}
                  onChange={handleChangeOp}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Correo Electrónico Operador</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={valuesOp.email}
                  onChange={handleChangeOp}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={valuesOp.phone}
                  onChange={handleChangeOp}
                />
              </Form.Group>

              <Form.Group controlId="formCompanyDescription" className="mb-3">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={valuesOp.description}
                  onChange={handleChangeOp}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={valuesOp.address}
                  onChange={handleChangeOp}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Región</Form.Label>
                <Form.Select
                  defaultValue={valuesOp.region}
                  name="region"
                  onChange={handleChangeOp}
                  required
                >
                  <option value="Arica y Parinacota">Arica y Parinacota</option>
                  <option value="Tarapacá">Tarapacá</option>
                  <option value="Antofagasta">Antofagasta</option>
                  <option value="Atacama">Atacama</option>
                  <option value="Coquimbo">Coquimbo</option>
                  <option value="Valparaíso">Valparaíso</option>
                  <option value="Metropolitana">Metropolitana</option>
                  <option value="O’Higgins">O’Higgins</option>
                  <option value="Maule">Maule</option>
                  <option value="Ñuble">Ñuble</option>
                  <option value="Biobío">Biobío</option>
                  <option value="Araucanía">Araucanía</option>
                  <option value="Los Ríos">Los Ríos</option>
                  <option value="Los Lagos">Los Lagos</option>
                  <option value="Aysén">Aysén</option>
                  <option value="Magallanes">Magallanes</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ciudad</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={valuesOp.city}
                  onChange={handleChangeOp}
                />
              </Form.Group>

              <Form.Group controlId="companyImageOp" className="mb-3">
                {valuesOp.image ? (
                  <div>
                    <img src={valuesOp.image} alt="" height={100} />
                  </div>
                ) : (
                  ""
                )}
                <Form.Label>Imagen del Operador</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChangeOp}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                disabled={isLoadingOp ? true : false}
              >
                {isLoading ? "Cargando..." : "Guardar Cambios"}
              </Button>
            </Form>
        </div>
        </div>:<></>}
        
      </Container>
    </div>
  );
};

export default UpdateUser;
