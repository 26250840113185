import React, { useEffect, useState, useContext } from "react";

import "./SearchBox.scss";

import { Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/es";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import EnergyOrder from "../EnergyOrder/EnergyOrder";

import { kwhToCo2Ton } from "../../helpers/helpers";
import { GlobalContext } from "../../contexts/GlobalContext";
import EnergyIcon from "../EnergyIcon/EnergyIcon";

import GtimeApiService from "../../services/GtimeApiService";
import EnergyOrderCarousel from "../EnergyOrderCarousel/EnergyOrderCarousel";

const SearchBox = () => {
  const globalData = useContext(GlobalContext);

  const [wallets, setWallets] = useState([]);
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);

  /**
   * Carga Wallets y Clientes y ejecuta primera búsqueda
   */
  useEffect(() => {
    const fetchWallets = async () => {
      const wallets = await GtimeApiService.getWallets();
      setWallets(wallets);
    };
    const fetchClients = async () => {
      const clients = await GtimeApiService.getClients();
      setClients(clients);
    };
    fetchWallets();
    fetchClients();
  }, []);

  /**
   * Filtros buscador
   */
  const [orderType, setOrderType] = useState("energyOffer");
  const [energySource, setEnergySource] = useState("Wind");
  const [sector, setSector] = useState("empresa");
  const [energyAmount, setEnergyAmount] = useState("");
  const [co2Mitigation, setCo2Mitigation] = useState("");
  const [location, setLocation] = useState("");
  const [showDefaultOrders, setShowDefaultOrders] = useState(true);
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();

  /**
   * Aplica filtros
   */
  const handleSubmitFilters = async (event) => {
    event.preventDefault();
    setShowDefaultOrders(false);
    setIsLoadingOrders(true);

    const findWalletById = (id) => {
      return wallets.find((wallet) => wallet.id === id);
    };

    const findClientById = (id) => {
      return clients.find((client) => client.id === id);
    };

    try {
      let energyOrders = await GtimeApiService.getEnergyOrders();

      energyOrders = energyOrders.map((order) => {
        order.wallet = findWalletById(order.walletId);
        order.client = findClientById(order.wallet.clientId);
        return order;
      });

      const filteredOrders = energyOrders.filter((order) => {
        const status = order.status === "sended";
        const filterType = order.type === orderType;
        const filterSource = order.energySource === energySource;
        const filterSector = order.client.sector === sector;
        var filterAmount = true;
        var filterMitigation = true;
        var filterLocation = true;
        var filterStartDate = true;
        var filterEndDate = true;

        if (energyAmount !== "" && order.type === "energyOffer") {
          filterAmount = order.energyAmount / 1000 >= energyAmount;
        }

        if (co2Mitigation !== "") {
          filterMitigation =
            kwhToCo2Ton(order.energyAmount, globalData.TONco2factor) >=
            co2Mitigation;
        }

        if (location !== "") {
          filterLocation = order.client.region === location;
        }

        if (startDateTime) {
          filterStartDate = moment.utc(order.dateFrom) <= startDateTime.local();
        }

        if (endDateTime) {
          filterEndDate = moment.utc(order.dateTo) >= endDateTime.local();
        }

        return (
          status &&
          filterType &&
          filterSource &&
          filterSector &&
          filterAmount &&
          filterMitigation &&
          filterLocation &&
          filterStartDate &&
          filterEndDate
        );
      });

      setOrders(filteredOrders);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const renderOrders = () => {
    if (isLoadingOrders) {
      return <p className="text-center">Cargando datos...</p>;
    }

    if (!showDefaultOrders && orders.length === 0) {
      return <p className="text-center">No se han encontrado coincidencias.</p>;
    }

    return (
      <Row>
        {orders.map((order) => {
          return (
            <Col xs={4} key={order.id} className="mb-4">
              <Link
                to={
                  "/" +
                  (order.type === "energyOffer" ? "purchase" : "sell") +
                  "/" +
                  order.id
                }
              >
                <EnergyOrder
                  energyOrder={order}
                  title={order.client.name}
                  image={order.client.image ?? "placeholder/cards/card-1.jpg"}
                  location={order.client.location}
                />
              </Link>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <div className="SearchBox">
      <Form className="filters" onSubmit={handleSubmitFilters}>
        <Row className="mb-3">
          <Col md={1}>
            <Form.Label>Buscar:</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Check
              inline
              type="radio"
              label="Ofertas de Generación"
              name="orderTypeFilter"
              id="orderTypeFilter-energyOffer"
              value="energyOffer"
              checked={orderType === "energyOffer"}
              onChange={(event) => setOrderType(event.target.value)}
            />
            <Form.Check
              inline
              type="radio"
              label="Subastas de Consumo"
              name="orderTypeFilter"
              id="orderTypeFilter-energyDemand"
              value="energyDemand"
              checked={orderType === "energyDemand"}
              onChange={(event) => setOrderType(event.target.value)}
            />
          </Col>
          <Col md={1}>
            <Form.Label>Dónde:</Form.Label>
          </Col>
          <Col md={4}>
            <Form.Check
              inline
              type="radio"
              label="Gran Empresa"
              name="sectorFilter"
              id="sectorFilter-empresa"
              value="empresa"
              checked={sector === "empresa"}
              onChange={(event) => setSector(event.target.value)}
            />
            <Form.Check
              inline
              type="radio"
              label="PYME"
              name="sectorFilter"
              id="sectorFilter-pyme"
              value="pyme"
              checked={sector === "pyme"}
              onChange={(event) => setSector(event.target.value)}
            />
            <Form.Check
              inline
              type="radio"
              label="Hogar"
              name="sectorFilter"
              id="sectorFilter-hogar"
              value="hogar"
              checked={sector === "hogar"}
              onChange={(event) => setSector(event.target.value)}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Cantidad de Energía</Form.Label>
            <InputGroup>
              <Form.Control
                id="cantidad-energia"
                name="cantidad-energia"
                value={energyAmount}
                onChange={(event) => setEnergyAmount(event.target.value)}
              />
              <InputGroup.Text>MWh</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col>
            <Form.Label>
              Mitigación de CO<sub>2</sub>
            </Form.Label>
            <InputGroup>
              <Form.Control
                id="mitigacion-energia"
                name="mitigacion-energia"
                value={co2Mitigation}
                onChange={(event) => setCo2Mitigation(event.target.value)}
              />
              <InputGroup.Text>Ton</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col>
            <Form.Label>Ubicación</Form.Label>
            <Form.Select
              defaultValue={location}
              name="location"
              id="location"
              onChange={(event) => setLocation(event.target.value)}
            >
              <option value="">- Cualquier ubicación -</option>
              <option value="Arica y Parinacota">Arica y Parinacota</option>
              <option value="Tarapacá">Tarapacá</option>
              <option value="Antofagasta">Antofagasta</option>
              <option value="Atacama">Atacama</option>
              <option value="Coquimbo">Coquimbo</option>
              <option value="Valparaíso">Valparaíso</option>
              <option value="Metropolitana">Metropolitana</option>
              <option value="O’Higgins">O’Higgins</option>
              <option value="Maule">Maule</option>
              <option value="Ñuble">Ñuble</option>
              <option value="Biobío">Biobío</option>
              <option value="Araucanía">Araucanía</option>
              <option value="Los Ríos">Los Ríos</option>
              <option value="Los Lagos">Los Lagos</option>
              <option value="Aysén">Aysén</option>
              <option value="Magallanes">Magallanes</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>Desde</Form.Label>
            <Datetime
              locale="es"
              onChange={(dateTime) => setStartDateTime(dateTime)}
            />
          </Col>
          <Col>
            <Form.Label>Hasta</Form.Label>
            <Datetime
              locale="es"
              onChange={(dateTime) => setEndDateTime(dateTime)}
            />
          </Col>
        </Row>

        <Form.Label>Tipo de Energía</Form.Label>

        <Row className="mb-3">
          <Col md={10}>
            <Form.Check inline className="radio-vertical-center">
              <Form.Check.Input
                className="radio-vertical-center"
                type="radio"
                name="energySourceFilter"
                id="energySourceFilter-Wind"
                value="Wind"
                checked={energySource === "Wind"}
                onChange={(event) => setEnergySource(event.target.value)}
              />
              <Form.Check.Label htmlFor="energySourceFilter-Wind">
                <EnergyIcon energySource="Wind" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check inline className="radio-vertical-center">
              <Form.Check.Input
                type="radio"
                name="energySourceFilter"
                id="energySourceFilter-Solar"
                value="Solar"
                checked={energySource === "Solar"}
                onChange={(event) => setEnergySource(event.target.value)}
              />
              <Form.Check.Label htmlFor="energySourceFilter-Solar">
                <EnergyIcon energySource="Solar" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check inline className="radio-vertical-center">
              <Form.Check.Input
                type="radio"
                name="energySourceFilter"
                id="energySourceFilter-Hydro"
                value="Hydro"
                checked={energySource === "Hydro"}
                onChange={(event) => setEnergySource(event.target.value)}
              />
              <Form.Check.Label htmlFor="energySourceFilter-Hydro">
                <EnergyIcon energySource="Hydro" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check inline className="radio-vertical-center">
              <Form.Check.Input
                type="radio"
                name="energySourceFilter"
                id="energySourceFilter-BioMasa"
                value="BioMasa"
                checked={energySource === "BioMasa"}
                onChange={(event) => setEnergySource(event.target.value)}
              />
              <Form.Check.Label htmlFor="energySourceFilter-BioMasa">
                <EnergyIcon energySource="BioMasa" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check inline className="radio-vertical-center">
              <Form.Check.Input
                type="radio"
                name="energySourceFilter"
                id="energySourceFilter-Thermal"
                value="Thermal"
                checked={energySource === "Thermal"}
                onChange={(event) => setEnergySource(event.target.value)}
              />
              <Form.Check.Label htmlFor="energySourceFilter-Thermal">
                <EnergyIcon energySource="Thermal" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check inline className="radio-vertical-center">
              <Form.Check.Input
                type="radio"
                name="energySourceFilter"
                id="energySourceFilter-Gas"
                value="Gas"
                checked={energySource === "Gas"}
                onChange={(event) => setEnergySource(event.target.value)}
              />
              <Form.Check.Label htmlFor="energySourceFilter-Gas">
                <EnergyIcon energySource="Gas" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check inline className="radio-vertical-center">
              <Form.Check.Input
                type="radio"
                name="energySourceFilter"
                id="energySourceFilter-Coal"
                value="Coal"
                checked={energySource === "Coal"}
                onChange={(event) => setEnergySource(event.target.value)}
              />
              <Form.Check.Label htmlFor="energySourceFilter-Coal">
                <EnergyIcon energySource="Coal" />
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={2}>
            <div className="d-grid">
              <Button
                type="submit"
                variant="primary"
                disabled={isLoadingOrders ? true : false}
              >
                {isLoadingOrders ? "Cargando..." : "Buscar"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      {showDefaultOrders && <EnergyOrderCarousel count={9} />}
      <div className="result-orders">{renderOrders()}</div>
    </div>
  );
};

SearchBox.propTypes = {};

SearchBox.defaultProps = {};

export default SearchBox;
