import React, { useContext } from "react";
import "./Profile.scss";

import { Container, Row, Col, Image } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContext";

import Wallet from "../Wallet/Wallet";

const Profile = () => {
  const { client } = useContext(UserContext);

  return (
    <div className="Profile mb-4">
      <div className="bg-light py-4">
        <Container>
          <Row>
            <Col xs={7}>
              <Row>
                <Col xs="4">
                  <div className="two-to-one-container mb-2">
                    <Image
                      className="img-fluid mb-3"
                      src={
                        client.operator.image ??
                        "/placeholder/placeholder-operator.png"
                      }
                      alt={client.name}
                    />
                  </div>
                  <div className="one-to-one-container">
                    <Image
                      className="img-fluid mb-3"
                      style={{ borderRadius: "50%" }}
                      src={
                        client.image ?? "/placeholder/placeholder-client.png"
                      }
                      alt={client.name}
                    />
                  </div>
                </Col>
                <Col xs="8">
                  <p className="m-0 text-primary">
                    <strong>
                      {client.operator.name} &middot; {client.operator.city}
                    </strong>
                  </p>
                  <h2>{client.name}</h2>
                  <p className="description-text">{client.description}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={5}>
              <Wallet />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
