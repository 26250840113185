import { React, useState, useEffect, useContext } from "react";
import "./Wallet.scss";

import { ReactComponent as Logo } from "../../assets/logo-gtime.svg";
import { Col, Row } from "react-bootstrap";

import GtimeApiService from "../../services/GtimeApiService";
import { UserContext } from "../../contexts/UserContext";

const Wallet = () => {
  const { isLoaddingUserData, wallet } = useContext(UserContext);
  const [isLoadingWallet, setIsLoadingWallet] = useState(true);
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    const fetchBalance = async () => {
      if (isLoaddingUserData) {
        return;
      }

      try {
        if (wallet) {
          const balance = await GtimeApiService.getBalanceByWalletId(wallet.id);
          setBalance(balance);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingWallet(false);
      }
    };
    fetchBalance();
  }, [wallet, isLoaddingUserData]);

  return (
    <div className="Wallet">
      {!isLoadingWallet && !isLoaddingUserData && !wallet ? (
        <p>Wallet no encontrada.</p>
      ) : (
        ""
      )}
      {isLoadingWallet ? (
        <p>Cargando Wallet...</p>
      ) : wallet && balance ? (
        <div className="wallet-data">
          <div className="wallet-logo">
            <Logo height={30}></Logo>
          </div>
          <Row className="mt-3">
            <Col>
              <h4>Energía Disponible en Venta:</h4>
              <p>
                {parseInt(balance.totalEnergyKWh).toLocaleString("es-CL")} kWh
              </p>
            </Col>
            <Col>
              <h4>Energía en Transacción:</h4>
              <p>
                {parseInt(
                  balance.trxGenKWh + balance.trxConsKWh
                ).toLocaleString("es-CL")}{" "}
                kWh
              </p>
            </Col>
          </Row>

          <div className="wallet-id">
            <h4>ID</h4>
            <p>{wallet.walletId}</p>
          </div>

          <hr />

          <Row className="mt-3">
            <Col>
              <h4>Saldo disponible para compra</h4>
              <p>
                <small>CLP</small> $
                {parseInt(balance.totalCLP).toLocaleString("es-CL")}
              </p>
            </Col>
            <Col>
              <h4>En transacción</h4>
              <p>
                <small>CLP</small> $
                {parseInt(balance.trxCLP).toLocaleString("es-CL")}
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        "Error cargando Wallet."
      )}
    </div>
  );
};

Wallet.propTypes = {};

Wallet.defaultProps = {};

export default Wallet;
