const GTIME_API_URL = process.env.REACT_APP_API_URL;
const ENDPOINT_HOME = "home";
const ENDPOINT_WALLETS = "wallets";
const ENDPOINT_BALANCES = "balances";
const ENDPOINT_CLIENTS = "clients";
const ENDPOINT_OPERATOR = "operators";
const ENDPOINT_ENERGYORDERS = "energyOrders";
const ENDPOINT_TRANSACTIONS = "transactions";
const ENDPOINT_CERTIFICATES = "certificates";
const ENDPOINT_NEGOTIATIONS = "negotiation";
const ENDPOINT_VERIFYCODE = "verifyCode";

const GtimeApiService = {
  async getHomeData() {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_HOME}`);
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getEnergyOrders() {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_ENERGYORDERS}`);
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getEnergyOrder(energyOrderId) {
    const response = await fetch(
      `${GTIME_API_URL}/${ENDPOINT_ENERGYORDERS}/${energyOrderId}`
    );
    const jsonData = await response.json();
    return jsonData.result;
  },

  async createEnergyOrder(energyOrder) {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_ENERGYORDERS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(energyOrder),
    });
    const jsonData = await response.json();

    if (jsonData.error) {
      throw new Error(jsonData.error.message);
    }

    return jsonData.result;
  },

  async getClients() {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_CLIENTS}`);
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getClient(clientId) {
    const response = await fetch(
      `${GTIME_API_URL}/${ENDPOINT_CLIENTS}/${clientId}`
    );
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getClientByEmail(clientEmail) {
    const clients = await this.getClients();
    const clientTmp = clients.find((client) => client.email === clientEmail);
    if (clientTmp && clientTmp.id) {
      return this.getClient(clientTmp.id);
    }
    return null;
  },

  async updateClient(clientId, clientData, clientImage) {
    try {
      const formData = new FormData();

      formData.append("name", clientData.name);
      formData.append("email", clientData.email);
      formData.append("phone", clientData.phone);
      formData.append("description", clientData.description);
      formData.append("address", clientData.address);
      formData.append("sector", clientData.sector);
      formData.append("region", clientData.region);
      formData.append("city", clientData.city);
      formData.append("location", clientData.location);
      formData.append("clientImage", clientImage);

      const response = await fetch(
        `${GTIME_API_URL}/${ENDPOINT_CLIENTS}/${clientId}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const responseBody = await response.json();
        if (!responseBody.result || !responseBody.result.id) {
          throw new Error("Error al actualizar cliente.");
        }
      } else {
        throw new Error(`Error al actualizar cliente: ${response.statusText}`);
      }
    } catch (error) {
      throw new Error(`Error al actualizar cliente: ${error.message}`);
    }
  },
  async updateOperator(operatorId, clientData, operatorImage) {
    try {
      const formData = new FormData();

      formData.append("name", clientData.name);
      formData.append("email", clientData.email);
      formData.append("phone", clientData.phone);
      formData.append("description", clientData.description);
      formData.append("address", clientData.address);
      formData.append("region", clientData.region);
      formData.append("city", clientData.city);
      formData.append("operatorImage", operatorImage);

      const response = await fetch(
        `${GTIME_API_URL}/${ENDPOINT_OPERATOR}/${operatorId}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const responseBody = await response.json();
        if (!responseBody.result || !responseBody.result.id) {
          throw new Error("Error al actualizar operador.");
        }
      } else {
        throw new Error(`Error al actualizar operador: ${response.statusText}`);
      }
    } catch (error) {
      throw new Error(`Error al actualizar operador: ${error.message}`);
    }
  },
  async getWallets() {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_WALLETS}`);
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getWallet(walletId) {
    const response = await fetch(
      `${GTIME_API_URL}/${ENDPOINT_WALLETS}/${walletId}`
    );
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getWalletByClientId(clientId) {
    const wallets = await this.getWallets();
    return wallets.find((wallet) => wallet.clientId === clientId);
  },

  async getBalanceByWalletId(walletId) {
    const response = await fetch(
      `${GTIME_API_URL}/${ENDPOINT_BALANCES}/${walletId}`
    );
    const jsonData = await response.json();
    return jsonData.result?.length ? jsonData.result[0] : null;
  },

  async getTransactions() {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_TRANSACTIONS}`);
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getCertificates() {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_CERTIFICATES}`);
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getNegotiations() {
    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_NEGOTIATIONS}`);
    const jsonData = await response.json();
    return jsonData.result;
  },

  async getNegotiation(negotiationId) {
    const response = await fetch(
      `${GTIME_API_URL}/${ENDPOINT_NEGOTIATIONS}/${negotiationId}`
    );
    const jsonData = await response.json();
    console.log("NEG", jsonData);
    return jsonData.result;
  },
  async setNegotiation(energyOrderId, verificationCode, userEmail) {
    const body = {
      energyOrderId: energyOrderId,
      verificationCode: verificationCode,
      userEmail: userEmail,
    };

    const response = await fetch(`${GTIME_API_URL}/${ENDPOINT_NEGOTIATIONS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((data) => data.json());

    return response.result;
  },
  async verifyCode(code, token) {
    const body = {
      userCode: code,
    };
    const res = await fetch(`${GTIME_API_URL}/${ENDPOINT_VERIFYCODE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }).then((data) => data.json());
    return res.code;
  },
  async createClient(formData) {
    const response = await fetch(`${GTIME_API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const jsonData = await response.json();

    if (jsonData.error) {
      throw new Error(jsonData.error.message);
    }

    return jsonData.result;
  },
  async getOperators() {
    const response = await fetch(`${GTIME_API_URL}/operators`);
    const jsonData = await response.json();
    return jsonData.result;
  },
  async getValidationData(hash) {
    const response = await fetch(`${GTIME_API_URL}/validator/${hash}`);
    const jsonData = await response.json();
    return jsonData.result;
  },
};

export default GtimeApiService;
