import React, { useEffect, useState, useContext } from "react";

import { Button, Container } from "react-bootstrap";
import {
  Row,
  Col,
  Popover,
  OverlayTrigger,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { ReactComponent as IconoAyuda } from "../../assets/icono-ayuda.svg";
import Profile from "../../components/Profile/Profile";
import ValidationCodeForm from "../../components/ValidationCodeForm/ValidationCodeForm";
import GtimeApiService from "../../services/GtimeApiService";

import { GlobalContext } from "../../contexts/GlobalContext";
import { UserContext } from "../../contexts/UserContext";

const Sell = () => {
  const { id } = useParams();

  const globalData = useContext(GlobalContext);

  const { client, wallet } = useContext(UserContext);
  const navigate = useNavigate();

  const [orderWallet, setOrderWallet] = useState(null);
  const [orderClient, setOrderClient] = useState(null);

  const [energyOrder, setEnergyOrder] = useState(null);
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);
  const [newEnergyOrder, setNewEnergyOrder] = useState(null);
  const [energyAmount, setEnergyAmount] = useState(0);
  const [clpPerKwh, setClpPerKwh] = useState(0);

  const [isCreatingNegotiation, setIsCreatingNegotiation] = useState(false);
  const [showValidationCodeForm, setShowValidationCodeForm] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);

  useEffect(() => {
    const fetchEnergyOrder = async () => {
      try {
        const energyOrder = await GtimeApiService.getEnergyOrder(id);
        setEnergyOrder(energyOrder);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEnergyOrder();
  }, [id]);

  useEffect(() => {
    const fetchWallet = async () => {
      if (!energyOrder) {
        return;
      }

      try {
        const orderWallet = await GtimeApiService.getWallet(
          energyOrder.walletId
        );
        setOrderWallet(orderWallet);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWallet();
  }, [energyOrder]);

  useEffect(() => {
    const fetchClient = async () => {
      if (!orderWallet) {
        return;
      }

      try {
        const orderClient = await GtimeApiService.getClient(
          orderWallet.clientId
        );
        setOrderClient(orderClient);
        setIsLoadingOrder(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchClient();
  }, [orderWallet]);

  const handleIsValidCodeChange = (checkIsValidCode) => {
    setIsValidCode(checkIsValidCode);
    setShowValidationCodeForm(false);

    if (!isValidCode) {
      Swal.fire({
        icon: "error",
        text: "El código ingresado no es válido.",
        confirmButtonText: "Aceptar",
      });
    }

    if (isValidCode) {
      setIsCreatingNegotiation(true);

      axios
        .post(process.env.REACT_APP_API_URL + "/energyOrders", newEnergyOrder)
        .then((response) => {
          console.log("CREATE ENERGY ORDER", response);
          const createdEnergyOrder = response.data.result;
          const newNegotiation = {
            buyerId: client.id,
            sellerId: orderClient.id,
            energyOrderOriginId: energyOrder.id,
            energyOrderDestinyId: createdEnergyOrder.id,
            energyPrice: parseInt(energyOrder.CLPperKWh),
            energyAmount: parseFloat(energyAmount) * 1000,
            type: "demandPurchase",
          };
          return axios.post(
            process.env.REACT_APP_API_URL + "/negotiation",
            newNegotiation
          );
        })
        .then((response) => {
          console.log("CREATE NEGOTIATION", response);
          if (response.data.result.id) {
            navigate("/sell/finish/" + response.data.result.id);
          }
          setIsCreatingNegotiation(false);
        })
        .catch((error) => {
          setIsCreatingNegotiation(false);
          console.error(error);
          Swal.fire({
            icon: "error",
            text: "Ocurrió un error y no se ha confirmado la oferta de venta.",
            confirmButtonText: "Aceptar",
          });
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (energyAmount === "") {
      Swal.fire({
        icon: "warning",
        text: "Debe ingresar la cantidad de energía de esta venta.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    const newEnergyOrder = {
      type: energyOrder.type === "energyOffer" ? "energyDemand" : "energyOffer",
      energyAmount: parseFloat(energyAmount * 1000),
      walletId: wallet.id,
      CLPperKWh: parseInt(clpPerKwh),
      dateFrom: energyOrder.dateFrom,
      dateTo: energyOrder.dateTo,
      energySource: energyOrder.energySource,
    };

    setNewEnergyOrder(newEnergyOrder);
    setShowValidationCodeForm(true);
  };

  return (
    <div className="Sell">
      <Profile />

      {isLoadingOrder ? (
        <Container className="mt-5">
          <p>Cargando datos...</p>
        </Container>
      ) : showValidationCodeForm ? (
        <Container className="mt-5">
          <ValidationCodeForm
            onIsValidCodeChange={handleIsValidCodeChange}
            seller={client}
            buyer={orderClient}
            newEnergyOrder={newEnergyOrder}
            type="sell"
          />
        </Container>
      ) : (
        <Container className="mt-5">
          <Row>
            <Col>
              <h1>Vender Generación</h1>
            </Col>
            <Col className="text-end"></Col>
          </Row>

          <Form onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-body">
                <div className="PurchaseOrder">
                  <Row>
                    <Col xs={3}>
                      <div className="data-head">{client.name}</div>
                      <div className="data-description">{client.address}</div>
                    </Col>
                    <Col xs={3}>
                      <div className="data-head">
                        {(energyOrder.energyAmount / 1000).toLocaleString(
                          "es-CL"
                        )}{" "}
                        MWh
                        <OverlayTrigger
                          trigger={("hover", "focus")}
                          placement="top"
                          overlay={
                            <Popover>
                              <Popover.Body>
                                Energía sustentable disponible para la compra en
                                MWh.
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <IconoAyuda height={15} className="mx-1" />
                        </OverlayTrigger>
                      </div>
                      <div className="data-description">
                        Energía Sustentable
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="data-head">
                        {(
                          energyOrder.energyAmount *
                          globalData["TONco2factor"] *
                          -1
                        ).toLocaleString("es-CL")}{" "}
                        Ton
                        <OverlayTrigger
                          trigger={("hover", "focus")}
                          placement="top"
                          overlay={
                            <Popover>
                              <Popover.Body>
                                Energía sustentable disponible para la compra en
                                MWh.
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <IconoAyuda height={15} className="mx-1" />
                        </OverlayTrigger>
                      </div>
                      <div className="data-description">
                        CO<sub>2</sub> en el ambiente
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="data-head">
                        ${energyOrder.CLPperKWh.toLocaleString("es-CL")} CLP/KWh
                      </div>
                      <div className="data-description">
                        Dispuesto a pagar por KWh
                      </div>
                      <p></p>
                    </Col>
                  </Row>

                  <hr />

                  <Row className="mt-3">
                    <Col xs={3} className="text-end text-uppercase">
                      <small>Cantidad de Energía a Vender</small>
                    </Col>
                    <Col xs={3}>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          id="mwh"
                          name="mwh"
                          placeholder=""
                          step="0.01"
                          min={0.01}
                          max={energyOrder.energyAmount / 1000}
                          value={energyAmount}
                          onChange={(event) =>
                            setEnergyAmount(event.target.value)
                          }
                        />
                        <InputGroup.Text>MWh</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col xs={3}>
                      <div className="data-head">
                        {(
                          energyAmount *
                          1000 *
                          globalData["TONco2factor"] *
                          -1
                        ).toLocaleString("es-CL")}{" "}
                        Ton
                      </div>
                      <div className="data-description">
                        CO<sub>2</sub> en el ambiente
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="data-head">
                        $
                        {(energyAmount * 1000 * clpPerKwh).toLocaleString(
                          "es-CL"
                        )}
                      </div>
                      <div className="data-description">TOTAL VENTA</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3} className="text-end text-uppercase">
                      <small>Precio unitario oferta</small>
                    </Col>
                    <Col xs={3}>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          id="clpPerKwh"
                          name="clpPerKwh"
                          placeholder=""
                          min={1}
                          value={clpPerKwh}
                          onChange={(event) => setClpPerKwh(event.target.value)}
                        />
                        <InputGroup.Text>CLP/KWh</InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <Row className="my-3">
              <Col>
                <Link to="/marketplace">
                  <Button variant="secondary">Cancelar</Button>
                </Link>
              </Col>
              <Col className="text-end">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isCreatingNegotiation}
                >
                  {isCreatingNegotiation ? "Cargando..." : "Vender generación"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </div>
  );
};
export default Sell;
