import React, { useState, useContext } from "react";
import "./CreateEnergyDemand.scss";

import {
  Row,
  Col,
  Popover,
  OverlayTrigger,
  Form,
  Button,
  Container,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IconoAyuda } from "../../../assets/icono-ayuda.svg";
import Profile from "../../../components/Profile/Profile";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { UserContext } from "../../../contexts/UserContext";
import { kwhToCo2TonStr, kwhToMwhStr } from "../../../helpers/helpers";
import EnergyIcon from "../../../components/EnergyIcon/EnergyIcon";
import Swal from "sweetalert2";
import moment from "moment";
import GtimeApiService from "../../../services/GtimeApiService";
import Datetime from "react-datetime";

const CreateEnergyDemand = () => {
  const globalData = useContext(GlobalContext);
  const { client, wallet } = useContext(UserContext);
  const navigate = useNavigate();

  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [energySource, setEnergySource] = useState("Wind");
  const [energyAmount, setEnergyAmount] = useState(0);
  const [clp, setClp] = useState(0);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const popoverMwh = (
    <Popover>
      <Popover.Body>
        Energía sustentable disponible para la venta en MWh.
      </Popover.Body>
    </Popover>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (energyAmount === "") {
      Swal.fire({
        icon: "warning",
        text: "Debes ingresar la cantidad de energía de esta oferta.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    if (clp === "") {
      Swal.fire({
        icon: "warning",
        text: "Debes ingresar el precio dispuesto a pagar.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    const newEnergyOrder = {
      type: "energyDemand",
      energyAmount: parseInt(energyAmount),
      walletId: wallet.id,
      CLPperKWh: parseInt(clp / energyAmount),
      dateFrom: moment(startDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.00"),
      dateTo: moment(endDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.00"),
      energySource: energySource,
    };

    setIsCreatingOrder(true);

    try {
      await GtimeApiService.createEnergyOrder(newEnergyOrder);
      setIsCreatingOrder(false);

      const ok = await Swal.fire({
        title: "Subasta de Consumo Creada",
        text: "La Subasta de Consumo de Energía fue creada exitosamente.",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
      if (ok.isConfirmed) {
        navigate("/usuario");
      }
    } catch (error) {
      console.error("ERROR CREANDO ENERGYORDER: ", error);
      setIsCreatingOrder(false);
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al crear la Subasta de Consumo de Energía. Intente otra vez.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }

    return;
  };

  return (
    <div className="CreateEnergyDemand">
      <Profile />

      <Container className="mt-5">
        <h1>Subastar Consumo de Energía</h1>
        <p>Creación de Subasta de Consumo de Energía</p>

        <Form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="PurchaseOrder">
                <Row>
                  <Col xs={3}>
                    <div className="data-head">{client.name}</div>
                    <div className="data-description">{client.address}</div>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      {kwhToMwhStr(0)} MWh
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popoverMwh}
                      >
                        <IconoAyuda height={15} className="mx-1" />
                      </OverlayTrigger>
                    </div>
                    <div className="data-description">Energía Sustentable</div>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      {kwhToCo2TonStr(0, globalData["TONco2factor"])} Ton
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popoverMwh}
                      >
                        <IconoAyuda height={15} className="mx-1" />
                      </OverlayTrigger>
                    </div>
                    <div className="data-description">
                      CO<sub>2</sub> en el ambiente
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="data-head">
                      ${(0).toLocaleString("es-CL")}
                    </div>
                    <div className="data-description text-uppercase">Total</div>
                    <p></p>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col xs={8}>
                    <p>Selecciona la fuente energética que quieres consumir.</p>

                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        className="radio-vertical-center"
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Wind"
                        value="Wind"
                        checked={energySource === "Wind"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Wind">
                        <EnergyIcon energySource="Wind" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Solar"
                        value="Solar"
                        checked={energySource === "Solar"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Solar">
                        <EnergyIcon energySource="Solar" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Hydro"
                        value="Hydro"
                        checked={energySource === "Hydro"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Hydro">
                        <EnergyIcon energySource="Hydro" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-BioMasa"
                        value="BioMasa"
                        checked={energySource === "BioMasa"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-BioMasa">
                        <EnergyIcon energySource="BioMasa" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Thermal"
                        value="Thermal"
                        checked={energySource === "Thermal"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Thermal">
                        <EnergyIcon energySource="Thermal" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Gas"
                        value="Gas"
                        checked={energySource === "Gas"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Gas">
                        <EnergyIcon energySource="Gas" />
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline className="radio-vertical-center">
                      <Form.Check.Input
                        type="radio"
                        name="energySourceFilter"
                        id="energySourceFilter-Coal"
                        value="Coal"
                        checked={energySource === "Coal"}
                        onChange={(event) =>
                          setEnergySource(event.target.value)
                        }
                      />
                      <Form.Check.Label htmlFor="energySourceFilter-Coal">
                        <EnergyIcon energySource="Coal" />
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col>
                    <Form.Label>Desde</Form.Label>
                    <Datetime
                      locale="es"
                      onChange={(dateTime) => setStartDateTime(dateTime)}
                      value={startDateTime}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Hasta</Form.Label>
                    <Datetime
                      locale="es"
                      onChange={(dateTime) => setEndDateTime(dateTime)}
                      value={endDateTime}
                    />
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col xs={2} className="text-end">
                    Requerimiento
                  </Col>
                  <Col xs={2}>
                    kWh
                    <Form.Control
                      type="number"
                      id="kwh"
                      name="kwh"
                      required
                      placeholder=""
                      step="1"
                      min={1}
                      value={energyAmount}
                      onChange={(event) => setEnergyAmount(event.target.value)}
                    />
                  </Col>
                  <Col xs={1}>...</Col>
                  <Col xs={3}>
                    Dispuesto a pagar CLP
                    <Form.Control
                      type="number"
                      id="clp"
                      name="clp"
                      required
                      placeholder=""
                      step="1"
                      min={1}
                      value={clp}
                      onChange={(event) => setClp(event.target.value)}
                    />
                  </Col>
                  <Col xs={1}>...</Col>
                  <Col xs={3}>
                    <div className="data-head">
                      $
                      {energyAmount
                        ? parseInt(clp / energyAmount).toLocaleString("es-CL")
                        : 0}{" "}
                      CLP/kWh
                    </div>
                    <p>Precio mínimo: XXXX CLP / kWh</p>
                    <p>Precio máximo: XXXX CLP / kWh</p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Row className="my-3">
            <Col>
              <Link to="/usuario">
                <Button variant="secondary">Cancelar</Button>
              </Link>
            </Col>
            <Col className="text-end">
              <Button
                type="submit"
                variant="primary"
                disabled={isCreatingOrder}
              >
                {isCreatingOrder ? "Cargando..." : "Subastar consumo"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
export default CreateEnergyDemand;
