import { useEffect, useState } from "react";
import EnergyOrder from "../../components/EnergyOrder/EnergyOrder";
import Slider from "react-slick";
import GtimeApiService from "../../services/GtimeApiService";
import { Link } from "react-router-dom";

const EnergyOrderCarousel = ({ count }) => {
  const [orders, setOrders] = useState([]);
  const [limit] = useState(count ?? 6);

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    const loadData = async () => {
      const wallets = await GtimeApiService.getWallets();
      const clients = await GtimeApiService.getClients();

      const findWalletById = (id) => {
        return wallets.find((wallet) => wallet.id === id);
      };

      const findClientById = (id) => {
        return clients.find((client) => client.id === id);
      };

      let orders = await GtimeApiService.getEnergyOrders();
      orders = orders.map((order) => {
        order.wallet = findWalletById(order.walletId);
        order.client = findClientById(order.wallet.clientId);
        return order;
      });

      const subset = orders.slice(-limit);
      setOrders(subset);
    };

    loadData();
  }, [limit]);

  return (
    <div className="HomeCarousel">
      <Slider {...carouselSettings}>
        {orders.map((order) => (
          <div key={order.id} className="p-2">
            <Link
              to={
                "/" +
                (order.type === "energyOffer" ? "purchase" : "sell") +
                "/" +
                order.id
              }
            >
              <EnergyOrder
                energyOrder={order}
                title={order.client.name}
                image={order.client.image ?? "placeholder/cards/card-1.jpg"}
                location={
                  order.client.location.length
                    ? order.client.location
                    : "\u00A0"
                }
              />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default EnergyOrderCarousel;
