import React, { useState } from "react";
import "./ChangePassword.scss";

import { Container, Form, Button, Row, Col } from "react-bootstrap";
import Profile from "../../../components/Profile/Profile";

import Swal from "sweetalert2";
// import { useAuth0 } from "@auth0/auth0-react";
import { useAuthState, useUpdatePassword } from "react-firebase-hooks/auth";
import { auth, signOut, app as firebase } from "../../../services/firebase";
const ChangePassword = () => {
  // const { user, logout } = useAuth0();
  const [user, loading, error] = useAuthState(auth);
  const [updatePassword, updating, updateError] = useUpdatePassword(auth);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      Swal.fire("Error", "Las contraseñas no coinciden", "error");
      return;
    }

    await updatePassword(newPassword)
      .then(() => {
        Swal.fire(
          "Éxito",
          "La contraseña se ha actualizado correctamente",
          "success"
        ).then(() => {
          signOut(auth);
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire("Error", "No se pudo actualizar la contraseña", "error");
      });
  };

  return (
    <div className="ChangePassword">
      <Profile />
      <Container>
        <div className="card shadow my-5">
          <div className="card-body">
            <h2 className="mb-4">Cambiar Contraseña</h2>
            <Row>
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="currentPassword" className="mb-3">
                    <Form.Label>Contraseña actual</Form.Label>
                    <Form.Control
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="newPassword" className="mb-3">
                    <Form.Label>Nueva contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="confirmPassword" className="mb-3">
                    <Form.Label>Confirmar nueva contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Actualizar contraseña
                  </Button>
                </Form>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ChangePassword;
