export function kwhToMwhStr(kwh) {
  return Number((kwh / 1000).toFixed(2)).toLocaleString("es-CL");
}

export function kwhToCo2TonStr(kwh = 0, factor = 1) {
  return Number((kwh * factor).toFixed(2)).toLocaleString("es-CL");
}

export function mwhTokTonCO2eq(mwh = 0, factor = 1 ){
  return Number((mwh * factor/1000).toFixed(0))
}

export function kwhToCo2Ton(kwh = 0, factor = 1) {
  return kwh * factor;
}