import React from 'react';
import { Container } from 'react-bootstrap';


const About = () => {
    return (
        <div className='About'>
            <Container>
                <h1 className='my-3 h3'>Quiénes Somos</h1>
            </Container>
        </div>
    )
}
export default About;